/**
 * エクスポート履歴トラン エンティティ
 */
import { isNud } from '@/utilities/typing';
import { dateYmdHi } from '@/utilities/date-format';
import ExportStatus from '@/models/enums/export-status';
import ExportLayout from '@/models/entities/export-layout';
import ExportJobDetail from '@/models/entities/export-job-detail';
import Retailer from '@/models/entities/retailer';
import User from '@/models/entities/user';

class ExportJob {
    export_job_id;
    user;
    export_datetime;
    export_type;
    export_layout;
    retailer;
    errata_type;
    period_from;
    period_to;
    required_can_view_production;
    export_note;
    export_status;
    file_path;
    export_job_details;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.export_job_id = properties.export_job_id;
        this.user = new User(properties.user);
        this.export_datetime = properties.export_datetime;
        this.export_type = properties.export_type;
        this.export_layout = new ExportLayout(properties.export_layout);
        this.retailer = new Retailer(properties.retailer);
        this.errata_type = properties.errata_type;
        this.period_from = properties.period_from;
        this.period_to = properties.period_to;
        this.required_can_view_production = properties.required_can_view_production;
        this.export_note = properties.export_note;
        this.export_status = properties.export_status;
        this.file_path = properties.file_path;
        this.export_job_details = [];
        if ('export_job_details' in properties) {
            for (let export_job_detail of properties.export_job_details) {
                this.export_job_details.push(new ExportJobDetail(export_job_detail));
            }
        }
    }

    get export_datetime_display() {
        return dateYmdHi(this.export_datetime);
    }

    get export_status_display() {
        return ExportStatus.get(this.export_status);
    }
}

export default ExportJob;
