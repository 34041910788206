<template>
    <page-title
        icon="bi-download"
        title="エクスポート履歴"
    >
    </page-title>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <template v-else>
            <p class="text-success">エクスポートの予約は製品一覧より行ってください。</p>

            <template v-if="export_jobs.length">
                <table-normal class="table">
                    <thead>
                        <tr class="table-dark">
                            <th class="text-center">実行日時</th>
                            <th class="text-center">形式</th>
                            <th class="text-center">メモ</th>
                            <th class="text-center">製品グループ数</th>
                            <th class="text-center">
                                ステータス
                                <button-exec
                                    text=""
                                    icon="bi-arrow-clockwise"
                                    color="btn-secondary"
                                    class="ms-2"
                                    @click="refresh()"
                                ></button-exec>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="export_job in export_jobs" :key="export_job.export_job_id">
                            <td class="align-middle text-center">{{ export_job.export_datetime_display }}</td>
                            <td class="align-middle text-center">{{ $helper.isNud(export_job.export_layout.export_layout_name) ?'正誤表' : export_job.export_layout.export_layout_name }}</td>
                            <td class="align-middle text-center">{{ export_job.export_note }}</td>
                            <td class="align-middle text-center">{{ export_job.export_job_details.length }}</td>
                            <td class="align-middle text-center">
                                <template v-if="export_job.export_status === ExportStatus.DONE">
                                    <!-- [製品情報閲覧権限]を持たない かつ ダウンロード内容に[製品情報閲覧権限が必要な項目]を保つ場合、disabled -->
                                    <button-exec
                                        text="ダウンロード"
                                        icon="bi-download"
                                        @click="downloadCsv(export_job)"
                                        :disabled="!$store.getters['auth/canViewProduction']() && export_job.required_can_view_production"
                                    ></button-exec>
                                </template>
                                <template v-else>
                                    {{ export_job.export_status_display }}
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table-normal>
            </template>
            <alert-no-record v-else></alert-no-record>
        </template>
    </section>
</template>

<script>
import { downloadBlob } from '@/utilities/download';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import ButtonExec from '@/components/buttons/ButtonExec.vue';
import TableNormal from '@/components/tables/TableNormal.vue';
import AlertNoRecord from '@/components/notice/AlertNoRecord.vue';
import PageTitle from '@/components/PageTitle.vue';
import ExportJob from '@/models/entities/export-job';
import ExportStatus from '@/models/enums/export-status';

export default {
    name: 'ExportJobList',
    components: {
    InlineLoader,
    ButtonExec,
    TableNormal,
    AlertNoRecord,
    PageTitle,
},
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            //部分ローダー
            loading: 0,

            //Enums
            ExportStatus: ExportStatus,

            //表示データ
            export_jobs: [],
        }
    },
    mounted() {
        this.fetchExportJobs();
    },
    methods: {
        //エクスポート履歴マスタ取得（エクスポート・正誤表）
        fetchExportJobs() {
            this.loading++;

            this.export_jobs.splice(0);

            this.$http.get('/export')
            .then(response => {
                for (let row of response.data) {
                    this.export_jobs.push(new ExportJob(row));
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        //CSVダウンロード
        downloadCsv(export_job) {
            this.startScreenLoading();

            this.$http.get(`/export/${export_job.export_job_id}/csv`, {responseType: 'blob'})
            .then(response => {
                let filename = 'download.csv';
                if ('content-disposition' in response.headers) {
                    filename = response.headers['content-disposition'].replace((/attachment; filename="(.*)"/u), '$1');
                }
                downloadBlob(response.data, filename);
            })
            .catch(() => {
                //上記で responseType:'blob'を設定している（CSV用）ので、error.response.dataがblob形式で返る→ jsonじゃないので普通にはmessageを取得できない
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        refresh() {
            this.fetchExportJobs();
        },
    }
}
</script>

<style scoped>

</style>
