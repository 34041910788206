/**
 * エクスポート明細トラン エンティティ
 */
import { isNud } from '@/utilities/typing';
import ExportJob from '@/models/entities/export-job';
import ProductGroup from '@/models/entities/product-group';

class ExportJobDetail {
    export_job_detail_id;
    export_job;
    product_group;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.export_job_detail_id = properties.export_job_detail_id;
        this.export_job = new ExportJob(properties.export_job);
        this.product_group = new ProductGroup(properties.product_group);
    }
}

export default ExportJobDetail;
