import Enum from './enum'

/**
 * エクスポートトラン ステータス
 */
class ExportStatus extends Enum {
    static WAITING = 1; // 作成待ち
    static WIP = 2; // 作成中
    static DONE = 3; // 作成済
    static DELETED = 8; // 削除済
    static ERROR = 9; // エラー

    static values() {
        return {
            [this.WAITING]: '作成待ち',
            [this.WIP]: '作成中',
            [this.DONE]: '作成済',
            [this.DELETED]: '削除済',
            [this.ERROR]: 'エラー',
        }
    }
}

export default ExportStatus;
